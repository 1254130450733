const xrefs = {
  MX: {
    url: '/matrix/MX{value}.html',
    name: 'PRODORIC'},
  DOI: {
    url: 'https://www.doi.org/{value}',
    name: 'DOI'},
  PUBMED: {
    url: 'https://www.ncbi.nlm.nih.gov/pubmed/?term={value}',
    name: 'Pubmed'},
  BACDIVE: {
    url: 'https://bacdive.dsmz.de/strain/{value}',
    name: 'BacDive'},
  EC: {
    url: 'https://www.brenda-enzymes.org/enzyme.php?ecno={value}',
    name: 'BRENDA'},
  UNIPROT: {
    url: 'https://www.uniprot.org/uniprot/{value}',
    name: 'Uniprot'},
  GENBANK: {
    url: 'https://www.ncbi.nlm.nih.gov/nuccore/{value}',
    name: 'Genbank'},
  KEGG: {
    url: 'https://www.genome.jp/kegg-bin/show_organism?org={value}',
    name: 'KEGG'},
  PDB: {
    url: 'https://www.rcsb.org/structure/{value}',
    name: 'PDB'
  }
}

export default {
  methods: {
    xref_link(name, acc) {
      if (name.toUpperCase() in xrefs) {
        return xrefs[name.toUpperCase()].url.replace('{value}', acc)
      } else {
        return ''
      }
    },
    xref_name(name) {
      if (name.toUpperCase() in xrefs) {
        return xrefs[name.toUpperCase()].name
      } else {
        return name.toUpperCase()
      }
    },
    processLinks: function(text) {
      // Check if there is a link [XXX:link] and
      // convert to actual link.
      let idstring = Object.keys(xrefs).join('|')
      const re = RegExp(`(${idstring}*):(.*)`, 'i')
      
      if (re.test(text)) {
        const m = text.match(re)
        if (m[1].toUpperCase() in xrefs) {
          let link = xrefs[m[1].toUpperCase()].url.replace('{value}', m[2])
          if (xrefs[m[1].toUpperCase()].url.startsWith('http')) {
            text = text.replace(
              re, `<a href="${link}" target="_blank">${m[2]}</a>`
            )
          } else {
            text = text.replace(
              re, `<a href="${link}">MX${m[2]}</a>`
            )
          }
        }
      }
      return text
    },
    highlightText(text, term) {
      if (term.length < 3) return text
      let res = text
      let idx = text.toLowerCase().indexOf(term.toLowerCase())
      if (idx > -1) {
        res = text.slice(0, idx) +
        `<mark>${text.slice(idx, idx + term.length)}</mark>` +
        text.slice(idx + term.length, text.length)
      }
      return res
    }
  }

}
  