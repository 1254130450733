import axios from 'axios'
// import router from '@/router'
import config from '@/config.js'

const DEBUG = process.env.NODE_ENV === 'development'

axios.defaults.baseURL = config.API_URL

axios.interceptors.request.use(
  (config) => {
    config.start = new Date()
    // If the user is logged in, automatically add the users token
    if (localStorage.getItem('token') !== null) {
      console.log('Token found, adding to config header')
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }
    return config
  }, 
  (request) => {
    // do something with request meta data, configuration, etc
    // dont forget to return request object,
    // otherwise your app will get no answer

    return request
  }
)

axios.interceptors.response.use(   
  (response) => {
    let end = new Date()
    let diff = end - response.config.start
    if (DEBUG) {
      console.log(`The API request to '${response.config.url}' took ${diff/1000}s`)
    }
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axios