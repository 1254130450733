export default {
  data: () => {
    return {
      window_width: 0,
      resize_timer: undefined,
    }
  },
  computed: {
    mx() {
      let mx = `${this.matrix.mx || this.matrix_id}`
      mx = mx.length < 6 ? '0'.repeat(6-mx.length)+mx : mx
      return mx
    }
  },
  mounted () {
    window.addEventListener('resize', this.updateSize)
    this.updateSize()
  },
  unmounted () {
    window.removeEventListener('resize', this.updateSize)
  },
  methods: {
    updateSize() {
      this.window_width = window.innerWidth
    }
  }
}
