<template>
  <div class="toast" :class="type">
    <fa :icon="icon" size="2x" />
    <div class="message"><slot/></div>
    <progress v-if="duration" :style="{width: `${width}%`}"></progress>
    <fa :icon="closeIcon" class="close" @click="close" />
  </div>
</template>

<script>
import {
  faTimesCircle, faExclamationTriangle, faInfoCircle, faComment, faCheckCircle
} from '@fortawesome/free-solid-svg-icons'

export default {
  data() {
    return {
      time: 0,
      width: 0,
      closeIcon: faTimesCircle
    }
  },
  props: {
    id: String,
    title: {type: String, default: ''},
    type: {type: String, default: ''},
    duration: {type: Number, default: 0},
    closable: {type: Boolean, default: true}
  },
  methods: {
    decrease: function() {
      this.time -= 100
      this.width = this.time/this.duration*100
      let self = this
      if (this.time > 0) {
        setTimeout(function() { self.decrease() }, 100)
      } else {
        this.$emit('remove', this.id)
      }

    },
    close: function() {
      this.$emit('remove', this.id)
    }
  },
  computed: {
    icon: function() {
      let icon = faComment
      switch(this.type) {
        case 'success':
          icon = faCheckCircle
          break
        case 'error':
          icon = faExclamationTriangle
          break
        case 'warning':
          icon = faInfoCircle
          break
        default:
          icon =  faComment
      }
      return icon
    }
  },
  mounted: function() {
    if (this.duration > 0) {
      this.width = 100
      this.time = this.duration
      this.decrease()
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/sass/tubs.sass'
@import '@/sass/variables.sass'
.toast
  position: relative
  left: 0
  right: 0
  margin: .5rem auto
  padding: 0 .25rem
  border: 1px solid
  border-left: .25rem solid transparent
  border-radius: 2px
  pointer-events: none
  box-shadow: 0px 0px 9px rgba(0,0,0, 0.25)
  // transform: translate3d(0, 0, 0)
  color: rgba(white, .9)
  max-width: 500px
  border-color: $grey80
  background: rgba($grey80, 0.9)
  display: flex
  justify-content: space-between
  align-items: center
  height: 50px
  overflow: hidden
  border-radius: $border-radius

  progress
    display: block
    position: absolute
    left: 0
    top: 0
    height: 2px
    border-radius: 0
    border: 0
    background: transparent
    transition: width 0.1s
  progress::-moz-progress-bar
    background: rgba(white, 0.5)
    width: 100%
  .close
    opacity: 0.8
    cursor: pointer
    pointer-events: all
    &:hover
      opacity: 1
  .message
    padding: 1rem
  &.error
    border-color: $tured
    background: rgba($tured, 0.9)
  &.success
    border-color: $green80
    background: rgba($green80, 0.9)
  &.warning
    border-color: $yellow
    background: rgba($yellow, 0.9)
.toast-enter-active
  transition: height .4s ease, max-width .4s ease .4s

.toast-leave-active
  transition: height .4s ease .4s, max-width .4s ease

.toast-enter-from, .toast-leave-to
  height: 0
  max-width: 50px


</style>
