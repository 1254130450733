export default {
  data: function() {
    return {
      auth_user: undefined
    }
  },
  methods: {
    update_auth: function() {
      // Checks if the current token is expired
      const token = localStorage.getItem('token')
      if (token !== null) {
        const parts = token.split('.')
        const data = JSON.parse((window.atob(parts[1])))

        const exp = new Date(data.exp * 1000)
        const now = new Date()

        if (now <= exp) {
          this.auth = true
          this.axios.get('/auth/me', {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
              this.auth_user = res.data
            })
            .catch(error => {
              this.$root.$refs.toaster.addToast('Auth', 'error', error)
              localStorage.removeItem('token')
              this.auth = false
              this.auth_user = undefined
              return false
            })
          console.log(`Your token is valid for ${((exp-now)/1000/60).toFixed(2)}min`)
          return true
        }
      }
      localStorage.removeItem('token')
      this.auth = false
      this.auth_user = undefined
      return false
    }
  },
  mounted() {
    if(this.$options.name === "Main") {
      this.update_auth()
    }
  }
}
