<template>
<div id="toaster">
  <transition-group name="toast">
    <Toast @remove="removeToast" v-for="toast in toasts" :key="toast.id" :title="toast.title" :type="toast.type" :id="toast.id" :duration="toast.duration">{{toast.msg}}</Toast>
  </transition-group>
</div>
</template>

<script>
import Toast from '@/components/Toast'
export default {
  components: {
    Toast
  },
    name: 'Toaster',
    data: () => {
      return {
        toasts: []
      }
    },
    props: {
    },
    computed: {
    },
    methods: {
      removeToast(id) {
        let idx = this.toasts.findIndex((x) => x.id == id)
        this.toasts.splice(idx, 1)
      },
      addToast(title, type, msg, duration) {
        this.toasts.unshift(
          {id: Math.random().toString(36).substr(2, 8), title, type, msg, duration}
        )
      }
    },
    mounted() {
    }
}
</script>

<style lang="sass">
#toaster
  margin: auto
  position: fixed
  pointer-events: none
  z-index: 1000
  left: 0
  right: 0
  top: 0
</style>
