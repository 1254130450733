const _md = require('markdown-it')({ typographer: true })
// based on:
// https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer
// Remember old renderer, if overridden, or proxy to default renderer
var defaultRender = _md.renderer.rules.link_open || function(tokens, idx, options, env, self) {
  return self.renderToken(tokens, idx, options)
}

_md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  // If you are sure other plugins can't add `target`
  // - drop check below
  let aIndex = tokens[idx].attrIndex('target')
  console.log(tokens, idx, options, env, self)
  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank'])
  } else {
    tokens[idx].attrs[aIndex][1] = '_blank'
  }

  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self)
}


export default {
  methods: {
    md: function(input) {
      return _md.render(input)
    }
  }
}
