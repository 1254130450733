export default {
  computed: {
    mx() {
      let mx = `${this.matrix.mx || this.matrix_id}`
      mx = mx.length < 6 ? '0'.repeat(6-mx.length)+mx : mx
      return mx
    },
    mx_favs() {
      let favs = []
      if (this.$cookies.isKey('prodoric-favorites')) {
        favs = (this.$cookies.get('prodoric-favorites') || '').split(';')
      }
      return favs
    }
  },
  methods: {
    to_mx: function(id) {
      let mx = `${id}`
      mx = mx.length < 6 ? '0'.repeat(6-mx.length)+mx : mx
      return mx
    },
    mx_add_favorite(mx) {
      let favs = this.mx_favs
      if (!favs.includes(mx)) {
        favs.push(mx)
      }
      this.$cookies.set('prodoric-favorites', favs.join(';'))
    },
    mx_remove_favorite(mx) {
      let favs = this.mx_favs
      let idx = favs.indexOf(mx)
      if (idx > -1) {
        favs.splice(idx, 1)
      }
      this.$cookies.set('prodoric-favorites', favs.join(';'))
    },
  }
}
