import { createRouter, createWebHistory } from 'vue-router'

function load_view(view) {
  return () => import(`@/views/${view}.vue`)
}


const routes = [
  { path: '/', alias: '/index.html', name: 'Index', component: load_view('Index')},

  { path: '/statistics.html', name: 'Statistics', component: load_view('Statistics')},
  { path: '/help.html', name: 'Help', component: load_view('Help')},
  { path: '/about.html', name: 'About', component: load_view('About')},


  // Matrix
  { path: '/matrix/', alias: '/matrix/index.html',
    name: 'MatrixList',component: load_view('MatrixList'), props: {page: '1'}},
  { path: '/matrix/page-:page.html',
    name: 'MatrixListPage', component: load_view('MatrixList'), props: true },
  { path: '/matrix/MX:matrix_id(\\d{6}).html',
    name: 'MatrixDetail', component: load_view('MatrixDetail'), props: true },

  // Virtual footprint
  { path: '/vfp/', alias: '/vfp/index.html',
    name: 'VirtualfootprintForm', component: load_view('Virtualfootprint')},
  { path: '/vfp/:vfp_id.html',
    name: 'VirtualfootprintResult',
    component: load_view('VirtualfootprintResult'), props: true },

  // ProdoNet
  { path: '/prodonet/',
    name: 'Prodonet', component: load_view('Prodonet'),
    props: {matrix_id: undefined, acc: undefined},
    alias: '/prodonet/index.html'
  },
  { path: '/prodonet/:acc.html',
    name: 'ProdonetDetail', component: load_view('ProdonetDetail'), props: true,
  },
    
  // Genome Browser
  { path: '/genomebrowser/',
    name: 'GenomeBrowser', component: load_view('GenomeBrowser'),
    alias: '/genomebrowser/index.html'
  },
  { path: '/genomebrowser/:acc.html', name: 'GenomeBrowserDetail',
    component: load_view('GenomeBrowserDetail'), props: true
  },

  { path: '/test/', name: 'Test', component: load_view('Test') },

  // News
  { path: '/news/', name: 'News', component: load_view('News') },
  { path: '/news/:permalink.html', name: 'NewsDetail',
    component: load_view('NewsDetail'), props: true },

  // Tasks
  { path: '/tasks/', name: 'Tasks', component: load_view('Tasks'), meta: {auth: 3} },

  // Auth
  { path: '/login.html', name: 'Login', component: load_view('Login') },
  { path: '/logout.html', name: 'Logout', component: load_view('Login'), props: {logout: true} },

  // Catch all 404 route
  { path: '/:pathMatch(.*)*', name: 'Error404', component: load_view('Error404')}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.is_auth = function(req_level) {
  // Checks if the current token is expired
  const token = localStorage.getItem('token')
  if (token !== null) {
    const parts = token.split('.')
    const data = JSON.parse(window.atob(parts[1]))
    const exp = new Date(data.exp * 1000)
    const now = new Date()
    if (now <= exp && data.level >= req_level) {
      return true
    }
  }
  localStorage.removeItem('token')
  return false
}

router.beforeEach((to, from, next) => {
  let needsAuth = to?.meta?.auth || 0
  if(needsAuth === 0 || router.is_auth(needsAuth)) {
    next()
    return
  }
  next({name: 'Login', params: { next: to.name} })
})


export default router
