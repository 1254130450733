import { useRoute } from "vue-router"

function getTitle(vm) {
  const { title } = vm.$options
  if (title) {
    return typeof title === 'function'
      ? title.call(vm)
      : title
  }
}

const DEFAULT_TITLE = 'PRODORIC'

export default {
  created () {
    const link = document.querySelector("link[rel='canonical']")
    link.setAttribute("href", `https://www.prodoric.de${useRoute().path}`)
    if (this.$parent?.name === 'default') {
      const title = getTitle(this)
      if (title) {
          document.title = `${title} - ${DEFAULT_TITLE}`
      } else {
          document.title = DEFAULT_TITLE
      }
    }
  }
}
