import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'

import SimpleWebWorker from 'simple-web-worker'

import VueAxios from 'vue-axios'
import VueCookies from 'vue3-cookies'
import axios from '@/axios'

import titleMixin from '@/mixins/titleMixin'
import matrixMixin from '@/mixins/matrixMixin'
import authMixin from '@/mixins/authMixin'
import linkMixin from '@/mixins/linkMixin'
import sizeMixin from '@/mixins/sizeMixin'
import genbankMixin from '@/mixins/genbankMixin'
import downloadMixin from '@/mixins/downloadMixin'
import cookieMixin from '@/mixins/cookieMixin'
import markdownMixin from '@/mixins/markdownMixin'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

import { faUnlock, faLock, faDownload, faBars, faSitemap, faMapMarked,
         faSearch, faChartLine, faBook, faInfo, faSpinner, faTrash,
         faExclamationTriangle, faEnvelope, faTimesCircle, faStar,
         faCookieBite,
         faCloudDownloadAlt
} from '@fortawesome/free-solid-svg-icons'

import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons'

import  { faFootprint, faPwm, faProdonet } from '@/icons'

library.add(
    faUnlock, faLock, faFootprint, faPwm, faDownload, faBars, faTrash,
    faSearch, faSitemap, faMapMarked, faChartLine, faBook, faInfo,
    faSpinner, faExclamationTriangle, faEnvelope, faTimesCircle, faStar,
    faStarRegular, faProdonet, faCookieBite, faCloudDownloadAlt
)

const app = createApp(App)
   .use(router)
   .use(VueAxios, axios)
   .use(VueCookies, {expireTimes: '365d'})

app.mixin(titleMixin)
   .mixin(matrixMixin)
   .mixin(authMixin)
   .mixin(linkMixin)
   .mixin(sizeMixin)
   .mixin(genbankMixin)
   .mixin(downloadMixin)
   .mixin(cookieMixin)
   .mixin(markdownMixin)

app.component('fa', FontAwesomeIcon)
   .component('fa-stack', FontAwesomeLayers)

app.config.globalProperties.$worker = SimpleWebWorker


app.mount('#app')
