
import zlib from 'zlib'


export default {
  methods: {
    zip(input) {
      let json = JSON.stringify(input)
      let buf = Buffer.from(json, 'utf8')
      let zipped = zlib.deflateSync(buf).toString('base64')
      return zipped
    },
    unzip(input) {
      let unzipped = zlib.inflateSync(Buffer.from(input, 'base64')).toString()
      return JSON.parse(unzipped)
    },
    store(genomes) {
      console.log(genomes)
      localStorage.setItem('genomes', this.zip(genomes))
    },
    async store_genome(acc, genome) {
      localStorage.setItem(`genome:${acc}`, this.zip(genome))
    },
    calc_size() {
      let size = new Blob(Object.values(localStorage)).size/1024/1024
      this.storage_size = size.toFixed(2)
    },
    async parse_genbank(data) {
      let reEntry = /(?:\s{3,10}gene)\s* (complement\()?(?:join\()?<?(\d*)\.\.(?:[0-9,]*?\.\.)?>?(\d*)\){0,2}\n(.*?)(?=^\s{3,10}gene|ORIGIN)/gsm
      let reItem = /\/(locus_tag|product|EC_number|gene)=((?:".*?")|(?:\d*))/gms
      let gb = {}
      gb.cds = []

      gb.acc = data.match(/VERSION\s+(.+)/)[1]
      gb.organism = data.match(/DEFINITION\s+(.+)/)[1]
      

      // Process genbank CDS
      for (let m of data.matchAll(reEntry)) {
        let cds = {}
        cds.strand = m[1] !== undefined ? '-' : '+'
        for (let n of m[4].matchAll(reItem)) {
          let key = n[1]
          let val = n[2].replace(/\n|"/gmi, '').replace(/ +/gmi, ' ')
          cds[key] = val
        }

        // Do not include pseudo genes
        if (m.includes('/pseudo')) continue
        let n = m[4].match(/(?: {3,10}CDS)\s*(.*)\n/g)
        let p = []
        
        if (n === null) continue
        let pos = []
        for (let q of n[0].matchAll(/(\d*)\.\.\D?(\d*)/gm)) {
          let tmp = []
          for (let i=1; i<q.length; i++) {
            tmp.push(parseInt(q[i]))
            p.push(parseInt(q[i]))
          }
          pos.push(tmp)
        }
        
        let sp = Array.from(new Set(p))
        if (p.length != sp.length) {
          pos = [[p[0], p[p.length-1]]]
        }
        
        for (let [start, stop] of pos) {
          let startstop = {'start': start, 'stop': stop, 'join': []}
          if (n[0].includes('join') && p.length != sp.lengt) {
            startstop['join'] = [p[0], p[p.length-1]]
          }
          gb.cds.push({...cds, ...startstop})
        }

      }

      // Sort cds
      gb.cds.sort((a,b) => a['start'] - b['start'])

      // Save sequence
      let seq = data.match(/^ORIGIN\s+([atgcnrywsmkhbvdATGCNRYWSMKHBVD\n\s0-9]*)(?=\/\/)/m)
      gb.seq = [...seq[0].matchAll(/[atgcnrywsmkhbvdATGCNRYWSMKHBVD]+/gm)].join('').toUpperCase()

      return gb
    }
  }
}
