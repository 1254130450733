<template>
  <div class="wrapper">
    <header>
      <h1 id="logo">
        <router-link :to="{ name: 'Index'}">
          <img src="@/assets/logo_prodoric.svg"
            height=75 alt="PRODORIC" title="PRODORIC"
            v-if="window_width > 768">
          <img src="@/assets/logo_prodoric_small.svg"
            height=50 alt="PRODORIC" title="PRODORIC"
            v-else>
        </router-link>
      </h1>
      <nav v-if="menu || window_width > 768">
        <ul @click="menu=false">
          <li>
            <router-link :to="{ name: 'MatrixList' }">
              <fa icon="pwm" />Matrices
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'VirtualfootprintForm' }">
              <fa icon="footprint" /> Virtual Footprint
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Prodonet' }">
              <fa icon="sitemap" /> ProdoNet
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'GenomeBrowser' }">
              <fa icon="map-marked" /> Genome Browser
            </router-link>
          </li>
          <li>
            <a href="/api/">
              <fa icon="cloud-download-alt" /> API
            </a>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'Statistics' }">
              <fa icon="chart-line" /> Statistics
            </router-link>
          </li> -->
          <!-- <li>
            <router-link :to="{ name: 'Help' }">
              <fa icon="book" /> Help
            </router-link>
          </li> -->
          <li>
            <router-link :to="{ name: 'About' }">
              <fa icon="info" /> About
            </router-link>
          </li>
          <li v-if="auth_user !== undefined">
            <router-link :to="{ name: 'Tasks' }">
              <fa icon="book" /> Tasks
            </router-link>
          </li>
          <li v-if="auth_user !== undefined">
            <strong>{{auth_user.name}}</strong>
            <router-link class="small" :to="{ name: 'Logout' }">Logout</router-link>
          </li>
        </ul>
      </nav>
      <div id="menu-button" @click="menu=!menu">
        <fa icon="bars" />
      </div>
    </header>
    <Toaster ref="toaster"></Toaster>
    <router-view/>
    <footer>
      <aside class="footer-copyright">PRODORIC &copy; 2003-{{year}} The PRODORIC Team</aside>
    
      <aside>
        <a href="https://creativecommons.org/licenses/by-nc/4.0/"
          target="_blank" rel="noreferrer"
        ><img src="@/assets/by-nc.svg" title="CC BY-NC license" /></a>
        PRODORIC is licensed under
        <a href="https://creativecommons.org/licenses/by-nc/4.0/"
          target="_blank" rel="noreferrer"
        >Creative Commons Attribution-NonCommercial 4.0 International License</a>,
        if you would like to use PRODORIC commecially please
        <router-link :to="{ name: 'About' }">contact us</router-link>.
      </aside>
      <address>
        Institute of Microbiology<br>
        Braunschweig Integrated Centre of Systems Biology (BRICS)<br>
        Technische Universität Braunschweig<br>
        38106 Braunschweig, Germany
      </address>
      <aside class="footer-logos">
        <a href="http://www.tu-braunschweig.de"
          title="Technische Universität Braunschweig"
          target="_blank" rel="noreferrer"
        >
        <img src="@/assets/logo_tubs.webp" alt="Technische Universität Braunschweig">
        </a>
        <a href="http://www.tu-braunschweig.de/brics"
          title="Braunschweig Integrated Centre of Systems Biology"
          target="_blank" rel="noreferrer"
        >
        <img src="@/assets/logo_brics.webp" alt="Braunschweig Integrated Centre of Systems Biology">
        </a>
        <a href="http://www.tu-braunschweig.de/mibi"
          title="Institute of Microbiology"
          target="_blank" rel="noreferrer"
        >
        <img src="@/assets/logo_mibi.webp" alt="Institute of Microbiology">
        </a>
      </aside>
      <aside class="footer-version">
        <span v-for="(v, i) in version" :key=v.name>
          {{v.name}}: {{v.version}}
          <template v-if="i < version.length-1"> | </template>
        </span>
      </aside>
    </footer>
    <div class="cookie_consent_banner" v-if="cookie_optout===null">
      <fa icon="cookie-bite" />
      <div>PRODORIC uses cookies for a better user experience.
      <router-link :to="{ name: 'About', hash: '#cookies' }">
      Learn more about cookies used on PRODORIC here
      </router-link>!</div>
      <div>
        <button class="small" @click="cookie_accept()">Accept cookies!</button>
        <button class="small" @click="cookie_reject()">No cookies for me!</button>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios'
import config from '@/config.js'
import Toaster from '@/components/Toaster'
export default {
  name: 'Main',
  components: {
    Toaster
  },
  data: () => {
    return {
      version: [{name: 'prodoric-frontend', version: config.VERSION}],
      auth: false,
      user: 'Guest',
      menu: false,
      year: new Date().getFullYear(),
    }
  },
  created() {
    let vm = this
    this.axios.get('/version').then(res => {
      this.version = this.version.concat(res.data)
    })
    axios.interceptors.response.use(   
      (response) => {
        return response
      },
      (error) => {
        let msg = ''
        if(error.response === undefined) {
          msg = `An error occured durig your request.`
        } else {
          msg = `Error ${error.response.status}: ${error.response.data.detail}`
        }
        vm.$root.$refs.toaster.addToast(
          'Error', 'error', msg
        )
      }
    )
  },
  computed: {
  },
  methods: {

  },
  beforeUpdate() {
  },
  mounted() {
  }
}
</script>

<style lang="sass">

*, *::before, *::after
  margin: 0
  padding: 0
  box-sizing: border-box
html, body
  height: 100%
  width: 100%

sub, sup 
  vertical-align: top
  position: relative
  top: -0.25em

h1, h2, h3, h4, h5
  font-family: 'Roboto Condensed', Arial, sans-serif
  font-weight: bold
  margin-top: 1rem
  .svg-inline--fa
    font-size: 1rem

.svg-inline--fa
  vertical-align: 0

section
  margin: 0 .5rem

@keyframes popout 
  from 
    transform: scale(1)
    color: $grey40
  
  33% 
    transform: scale(0.6)

  66% 
    transform: scale(1.2)
  
  to 
    transform: scale(1)
    color: $yellow

.fav-star
  height: inherit
  font-size: inherit
  line-height: inherit
  cursor: pointer
  color: $grey20
  &.fav
    color: $yellow
    &:hover
      color: $yellow60
    &.added
      animation: popout .5s normal forwards ease-in-out
  &:hover
    color: $grey40

.cookie_consent_banner
  position: absolute
  top: 0
  background: rgba($tured, .9)
  width: 100%  
  color: white
  position: fixed
  padding: 1rem
  z-index: 999
  display: flex
  justify-content: center
  align-items: center
  svg
    line-height: 2rem
    font-size: 2rem
    margin: .5rem
  a, a:active, a:visited
    color: white
    text-decoration: underline
  a:hover
    color: lightgray !important
    text-decoration: none

#app, .wrapper
  min-height: 100vh

#app > .wrapper
  font-family: 'Roboto Condensed', Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $grey80
  min-height: 100vh
  display: grid
  grid-template-areas: "header" "main" "footer"
  grid-template-columns: 1fr
  grid-template-rows: $nav-height 1fr auto
  color: $grey80

#app > .wrapper > header
  border-bottom: 2px solid $tured
  padding: 0 .5rem
  margin: 0
  width: 100%
  background: #ffffffee
  position: fixed
  height: $nav-height
  z-index: 10
  display: flex
  justify-content: space-between
  align-items: center
  grid-area: header

  @media (min-width: $mbp-s)
    position: relative
    align-items: center
  h1
    margin: 0
    padding: 0
    height: 2rem
    @media (min-width: $mbp-s)
      height: 3.5rem
  #logo
    img
      height: 2rem
      @media (min-width: $mbp-s)
        height: 3.5rem
  #menu-button
    font-size: 1.5rem
    cursor: pointer
    @media (min-width: $mbp-s)
      display: none
  //
  // Navigation
  //
  nav
    width: 100%
    padding: 0
    margin: 0
    background: #ffffffee
    a
      color: $grey80
      &:visited
        color: $grey60
    a.router-link-exact-active
      font-weight: bold
      color: $tured
    ul
      display: flex
      list-style-type: none
      justify-content: space-evenly
      margin: 0
      align-items: center
      li
        a
          display: flex
          align-items: center
          svg
            margin-right: .5rem

    @media (max-width: $mbp-s)
      position: absolute
      left: 0
      top: 4rem
      a
        padding: 1rem
        display: inline-block
        width: 100%
        height: 100%
        &:hover
          background-color: #eeeeeeee
      ul
        display: block
        margin: 0
        li
          padding: 0
          border-bottom: 1px solid $grey60
          svg
            margin-right: 1rem
#app > .wrapper > main
  grid-area: main
  background-color: white
  padding: 0
  max-width: 1024px
  width: 100%
  margin: 0 auto
  // padding: 1rem

//
// Footer
//
#app > .wrapper > footer
  grid-area: footer
  background-color: $grey20
  padding: .5em
  display: flex
  flex-direction: column


  @media (min-width: $mbp-s) 
    flex-direction: row
    flex-wrap: wrap
    & > *
      flex: 1 0 33%
  img
    display: block
    margin: .5rem auto
    padding: .5rem
  a
    color: #333
    opacity: .5
    text-decoration: none
  a:hover
    opacity: 1
  .footer-copyright
    text-align: center
    flex: 0 1 100%
    padding: .5rem
  .footer-version
    font-size: .5rem
    color: $grey60
    text-align: center
    flex: 0 1 100%
    padding: .5rem
  address
    align-self: center
    padding: .5rem
    font-size: 0.7rem
  .footer-logos
    justify-content: space-evenly
    display: flex
    align-items: center

    img
      padding: .25rem
      max-height: 70px
      width: 100%
      filter: grayscale(100%)
      opacity: 0.5
      transition: filter .5s, opacity .5s, mix-blend-mode .5s
      mix-blend-mode: multiply
      &:hover
        filter: grayscale(0%)
        opacity: 1
        mix-blend-mode: normal
  aside
    padding: 0 1em
    font-size: .6rem
    text-align: center

figure
  width: 100%
  overflow: scroll
  overflow-scrolling: touch
  background: linear-gradient(90deg, var(--background-color, white) 30%, rgba(255, 255, 255, 0)) left center, linear-gradient(90deg, rgba(255, 255, 255, 0), var(--background-color, white) 70%) right center, radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)) left center, radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) right center
  background-repeat: no-repeat
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%
  background-attachment: local, local, scroll, scroll

table
  width: 100%
  border-collapse: collapse
  margin: 1rem 0
  border-top: 1px solid $grey20
  border-bottom: 1px solid $grey20
  .first
    background-color: $grey40
    font-weight: 900
    text-align: right
  th,td
    padding: .5rem
    margin: 0
  th
    background-color: $grey80
    color: white
    padding: .5rem
    font-size: .9rem
  tr
    height: 3rem

  tbody
    tr:nth-child(odd) td
      background: #eeeeee
    tr:nth-child(even) td
      background: #ffffff


//
// General stuff
//
hr
  height: 1px
  background: lightgray
  border: 0
  margin: .5rem

.inactive, .gray
  color: darkgray

small, .small
  font-size: .75rem
.tiny
  font-size: .6rem

a
  color: $tured
  opacity: 1
  text-decoration: none
a:visited
  color: lighten($tured, 15%)
  &:hover
    color: $tured
a:hover
  opacity: 1

.tt
  font-family: 'Roboto Mono', monospace
  font-size: 1rem

.bold
  font-weight: bold

.center
  text-align: center

ul
  margin-left: 1rem

button, input
  display: inline-block
  margin: .25rem
  padding: .5rem 1rem
  border: 1px solid $grey60
  border-radius: $border-radius



:is(.button, button):not([disabled]):not(.inactive)
  cursor: pointer
  padding: 0 .5rem
  display: flex
  align-items: center 
  border: 1px solid $grey60
  transition: transform .1s linear
  &:hover
    transform: translateY(-2px)
    box-shadow: 0 2px 5px $grey20
  &.red
    background: $tured
    color: white

button[disabled]
  color: $grey40
  border: 1px solid $grey20

textarea
  border: 1px solid $grey20
  border-radius: $border-radius
  overflow: auto
  resize: vertical
  font-size: .8rem
  width: 100%
  padding: .5rem

.buttons 
  color: black
  padding: 0
  margin: .25rem .25rem
  display: inline
  height: 1.7rem
  display: inline-flex
  align-items: center

  & > :is(button, input, .button)
    display: flex
    align-items: center
    border: none
    border-radius: 0
    margin: 0
    height: 100%
  &.block
    display: flex
  .red
    background: $tured
    color: white
      
  .label, .inactive
    background-color: $grey60
    color: #fff
    cursor: default
    display: inline-block
    padding: 0 .5rem
    height: 100%
    display: flex
    align-items: center
  :is(button, .button):not(.inactive)
    cursor: pointer
    &:not(.inactive):hover
      background-color: #fefefe
      transform: translate(0, 0)
      box-shadow: 0 0 0 0
    &:not(.inactive).red:hover
      background: lighten($tured, 10%)
    

  a.button
    color: black
    width: 100%
    height: 100%
    vertical-align: middle
    line-height: 1.8em
    background-color: #efefef
    &:hover
      background-color: #fff

  
  & *:is(input, button, .label, .inactive, .button):not(:first-child):not(:last-child) 
    border-width: 1px 0 1px 1px
    border-color: $grey80
    border-style: solid
    border-radius: 0
  &  *:is(input, button, .label, .inactive, .button):first-child 
    border-style: solid
    border-width: 1px 0 1px 1px
    border-color: $grey80
    border-radius: $border-radius 0 0 $border-radius
    // margin-left: .25rem
  & *:is(input, button, .label, .inactive, .button):last-child 
    border-width: 1px 1px 1px 1px
    border-color: $grey80
    border-style: solid
    border-radius: 0 $border-radius $border-radius 0
    // margin-right: .25rem
  & *:is(input, button, .label, .inactive, .button):first-child:last-child
    border: 1px solid $grey80
    border-radius: $border-radius
  span.input, span.white
    background-color: white
    color: $grey60
    border-width: 1px 0 1px 0!important
    
.buttons.center
  justify-content: center

.right
  display: flex
  justify-content: right

.nowrap
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap

#bindingsite_list
  thead tr
    th
      word-wrap: normal
      
    th:nth-child(1)
      width: 110px
    th:nth-child(2)
      width: 110px
    th:nth-child(3)
      text-align: center
    th:nth-child(5)
      width: 65px
    th:nth-child(6)
      width: 50px
      text-align: center
  tbody
    td:nth-child(3)
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap
    td:nth-child(2), td:nth-child(5), td:nth-child(6)
      text-align: center
a[id^='ref']
  top: -$nav-height
  visibility: hidden
  display: block
  position: relative

.tabbox
  position: relative
  & > div
    border: 1px solid $grey20
    padding: 1rem
    display: none
  & > div.selected
    display: block

.hidden
  display: none


.tabbox
  input
    width: 100%
.options
  div
    display: flex
    align-items: center
  label
    padding: .5rem
    display: inline-block
    width: 250px
mark
  background-color: rgba($tured, .3)
  text-decoration: underline 

.cards
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)) 
  list-style: none
  margin: 0

.card
  text-align: left
  border: 1px solid $grey80
  margin: .5rem
  padding: .5rem
  border-radius: $border-radius
  display: inline-block
  position: relative

.progress
  width: 90%
  height: 24px
  margin: 1rem auto
  border: 1px solid $grey80
  border-radius: $border-radius
  background: white
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4) inset
  & > span
    display: block
    appearance: none
    height: 20px
    margin: 1px
    max-width: calc(100% - 2px)
    border-radius: $border-radius
    transition: width .5s ease

.progress.PENDING
  background-color: lightgray
  background-size: 50px 50px
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
  animation: move forwards 40s infinite

.progress.PROCESSING > span
  background-size: 50px 50px
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
  animation: move forwards 20s infinite
  background-color: $yellow

.progress.FAILURE > span
  background-color: $tured

.progress.SUCCESS > span
  background-color: $green


@keyframes move 
  0%
    background-position: 0% 50%
  100%
    background-position: 100% 50%


.fade-enter-active
  transition: opacity .25s ease .25s
.fade-leave-active 
  transition: opacity .25s ease

.fade-enter-from,
.fade-leave-to 
  opacity: 0

.placeholder
  background-color: $grey20
  border-radius: 3px
  color: black
  opacity: 0.8
  outline: 1px solid #fff
</style>
