export default {
  data: function() {
    return {
      cookie_optout: undefined,
      cookie_optout_form: false
    }
  },
  mounted() {
    if (this.$cookies.get('prodoric-optout') === null) {
      this.cookie_optout = null
    } else {
      this.cookie_optout = this.$cookies.get('prodoric-optout') === 'true'
    }
    this.cookie_optout_form = this.$cookies.get('prodoric-optout') === 'true'
  },
  methods: {
    cookie_accept: function() {
      this.$cookies.set('prodoric-optout', false)
      this.cookie_optout = false
      this.cookie_optout_form = false
    },
    cookie_reject: function() {
      // Delete all existing cookies!
      this.$cookies.remove('prodoric-favorites')
      this.$cookies.remove('prodoric-vfp-jobs')
      this.$cookies.set('prodoric-optout', true)
      this.cookie_optout = true
      this.cookie_optout_form = true
     
    },
    cookie_optout_change: function() {
      // Check if cookie status has changed
      if (this.cookie_optout == this.cookie_optout_form) return
      if(this.cookie_optout_form === 'true') {
        this.cookie_reject()
        return
      }
      this.cookie_accept()
    }
  }
}
