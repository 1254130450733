export default {
  methods: {
    download(content, filename) {
      let linktag = document.createElement("a")
      linktag.setAttribute("href", content)
      linktag.setAttribute("download", filename)
      linktag.style.display = "none"
      document.body.appendChild(linktag)
      linktag.click()
      linktag.remove()
    },
    download_text(content, filename) {
      let out = `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`
      this.download(out, filename)
    },
  },
}
